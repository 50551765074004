import { useContext, useEffect, useState } from 'react'
import Config from '../config'
import { Redirect, useParams } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import { IEnrollResponse } from '../interfaces/mainInterfaces'
import { AppContext } from '../context/AppContext'
import SignupLayout from '../layouts/SignupLayout'
import styles from './SignupForm.module.scss'
import Logo from '../assets/images/egg_shaddow.svg';


interface Props {
    
}

const EnrollView = (props: Props) => {

    const [ ready, setReady ] = useState<boolean>(false)
    const [ error, setError ] = useState<string | undefined>(undefined) 
    const { token } = useParams<{ token: string }>()
    const { setEnrollData } = useContext(AppContext)
    

    useEffect(() => {

        if(token){
            window.localStorage.setItem('invitationToken', token)
            window.localStorage.clear()
            setEnrollData?.(undefined)
        } else {
            window.localStorage.getItem('invitationToken')
        }
        axios.get<AxiosResponse<IEnrollResponse>>(Config.API + '/user/enroll/token/' + token)
        .then((res) => {
            setEnrollData?.({...res.data.data, token: token})
            setReady(true)
        })
        .catch((error) => {
            setError('Invitation code is invalid')
            console.error('Error enrolling', error.message)
        })

    }, [token, setEnrollData])

    if(!token || error){
        return(
            <SignupLayout>
                <div className={styles['form-container']}>
                    <div className="formHeading">
                        <img src={Logo} width={60} alt="" />
                        <h1>Welcome</h1>
                        <p>{`Couln't find any invitation link`}</p>
                    </div>
                </div>
            </SignupLayout>
        )
    }

    if(!ready){
        return (
            <SignupLayout>
                <div className={styles['form-container']}>
                    <div className="formHeading">
                        <img src={Logo} width={60} alt="" />
                        <h1>Welcome</h1>
                        <p>{`Searching event information`}</p>
                    </div>
                </div>
            </SignupLayout>
        )
    }
    return <Redirect to={"/"}/>
}

export default EnrollView
