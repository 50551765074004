import React from 'react'
import styles from './SignupLayout.module.scss';
interface Props {
    children: JSX.Element
}

const SignupLayout = ({children}: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.imgContainer}>
                    {/* <img src={LogoLight} width={100} alt="Escuela+ Egg Logo" /> */}
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.leftCard}>
                        <h1>Welcome to Egg</h1>
                        <p>Our goal is to enhance Human Cooperation. We will have a cooperative challenge in today's session and each of us will experience our social technology</p>
                    </div>
                </div>
            </div>
            <div className={styles.right}>{children}</div>
        </div>
    )
}

export default SignupLayout
